import React, { useState, useCallback, useContext } from "react"
import WarningIcon from "@material-ui/icons/Warning"
import AppTitle from "../../components/appTitle/AppTitle"
import PageWrapper from "../../components/PageWrapper"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import Typography from "@material-ui/core/Typography"
import { formatCurrency } from "../../util/finance"

import * as overviewStyles from "./overview.module.css"

import { getTenancyOverview as apiGetTenancyOverview } from "../../services/tenantsApiV2"
import Frame from "../../components/frame/Frame"
import Frames from "../../components/frames/Frames"
import OptimizedTable from "../../components/optimizedTable/OptimizedTable"

import * as tenancyInformationStyles from "./tenancyInformation.module.css"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"

import { FaWhatsapp, FaEnvelope, FaCheckCircle } from "react-icons/fa";

import {
  getTenancyTenants as apiGetTenancyTenants,
  getTenancyContractServices as apiGetTenancyContractServices,
} from "../../services/tenantsApiV2"
import MaintenanceAlert from "./components/MaintenanceAlert";


export default ({ location, tenancyId }) => {
  const context = useContext(GlobalStateContext)
  const [overview, storeOverview] = useState(null)

  const shareMessage = encodeURIComponent("Hey! You haven't signed up for Bunch yet - join us here: ");

  const [tenants, storeTenants] = React.useState([])
  const [contractServices, storeContractServices] = React.useState([])
  const [getMyTenancyError, setGetMyTenancyError] = React.useState(null)
  const [gettingTenancyDetails, setGettingTenancyDetails] = useState(true)

  const getTenancyDetails = useCallback(async () => {
    try {
      setGettingTenancyDetails(true)
      const data = await Promise.all([
        apiGetTenancyOverview(tenancyId),
      ])

      if ((data[0] && data[0].value) || data.value) {
        storeOverview(data[0].value)
      }
    } catch (e) {
      console.log(e)
    }
    setGettingTenancyDetails(false)

    //console.log(d)
  }, [tenancyId])

  const getShareLinks = () => {
    return {
      whatsapp: `https://wa.me/?text=${shareMessage} ${encodeURIComponent(overview.houseInviteUrl)}`,
      email: `mailto:?subject=Join our Bunch!&body=${shareMessage} ${encodeURIComponent(overview.houseInviteUrl)}`,
    }
  }

  const getTenancyTenants = useCallback(async () => {
    try {
      const data = await Promise.all([
        apiGetTenancyTenants(tenancyId),
        apiGetTenancyContractServices(tenancyId),
      ])

      if (data[0] && data[0].value) {
        storeTenants(data[0].value)
      }

      if (data[1] && data[1].value) {
        storeContractServices(data[1].value)
      }
    } catch (e) {
      console.log(e)
    }
  }, [tenancyId])

  const getMyTenancy = useCallback(async () => {
    try {
      if (context.tenancy) {
        getTenancyTenants()
      }
    } catch (e) {
      console.log(e)
      setGetMyTenancyError(e.message)
    }
  }, [getTenancyTenants, tenancyId])

  React.useEffect(() => {
    getMyTenancy()
  }, [context.loggedIn, getMyTenancy])


  React.useEffect(() => {
    if (!process.env.GATSBY_USAGE_MAINTENANCE_MESSAGE){
    getTenancyDetails();
    getTenancyTenants();
    }
    setGettingTenancyDetails(false)
  }, [context.loggedIn])

  function formatISODateToMonthYear(dateString) {
    // Ensure input is a valid string
    if (!dateString || typeof dateString !== "string") return "Invalid date";

    try {
      // Extract only the date part (YYYY-MM-DD)
      const [datePart] = dateString.split("T"); // "2025-06-30"

      // Convert to Date object
      const date = new Date(datePart);

      // Validate if the Date object is valid
      if (isNaN(date.getTime())) {
        return "Invalid date format";
      }

      // Format to "Month YYYY"
      return date.toLocaleDateString("en-GB", { month: "short", year: "numeric" });
    } catch (error) {
      return "Invalid date format";
    }
  }

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={gettingTenancyDetails}
      showLoadingLogo={true}
      showLoadingText="Getting your live data"
    >
      {context.tenantUser && (
        <div className={overviewStyles.overview}>
          <AppTitle
            showBackHome={true}
            title={`Hi ${context.tenantUser.firstName}, here's your account overview!`}
          />
          {process.env.GATSBY_USAGE_MAINTENANCE_MESSAGE ? 
      <MaintenanceAlert
      title="Maintenance Work"
      message="Your bills will be offline due to maintenance work. We apologise for any inconvenience caused."
    /> : null}
          {!process.env.GATSBY_USAGE_MAINTENANCE_MESSAGE && overview && (
            <div>


              {overview.householdAccountSummary && (overview.householdAccountSummary.totalBills != null) && (overview.householdAccountSummary.totalPayments != null) &&
                <Frames>
                  <Frame title="Household Account Summary">
                    <div style={{ display: "flex", gap: "11vw", justifyItems: "flex-start", flexWrap: "wrap" }}>
                      <div className="text-center">
                        <Typography className="h6" style={{ margin: "5px 0px", fontWeight: "bold" }} >Total bills to date</Typography>
                        <Typography > {formatCurrency(overview.householdAccountSummary.totalBills)}</Typography>
                      </div>
                      <div className="text-center">
                        <Typography className="h6" style={{ margin: "5px 0px", fontWeight: "bold" }} >Total payments to date</Typography>
                        <Typography >{formatCurrency(overview.householdAccountSummary.totalPayments)}</Typography>
                      </div>
                      <div className="text-center">
                        <Typography className="h6" style={{ margin: "5px 0px", fontWeight: "bold" }}>Amount outstanding</Typography>
                        <Typography >{formatCurrency(overview.householdAccountSummary.amountOutstanding)}</Typography>
                      </div>
                    </div>
                  </Frame>
                </Frames>
              }


              <div className={tenancyInformationStyles.tenancyDetails}>

                {context.tenancy && (
                  <>
                    <div style={{ marginTop: 20 }}>
                      <Frames>
                        {
                          <Frame title={`Your Household - ${formatISODateToMonthYear(context.tenancy.startDate)} - ${formatISODateToMonthYear(context.tenancy.endDate)}`}>
                            <OptimizedTable
                              title="Household Members"
                              description={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",  // ✅ Proper spacing between text and icons
                                    alignItems: "center",
                                    width: "100%",  // ✅ Ensures it stretches to match the table width
                                    padding: "5px 0",  // ✅ Adds breathing space
                                  }}
                                >
                                  <span style={{ textAlign: "left" }}>
                                    Can't see some of your fellow billpayers? <br />
                                    Share this link with them:
                                  </span>
                                  <div style={{ display: "flex", gap: "15px" }}>  {/* ✅ Add spacing between icons */}
                                    <a href={getShareLinks().whatsapp} target="_blank" rel="noopener noreferrer">
                                      <FaWhatsapp size={30} color="#25D366" />
                                    </a>
                                    <a href={getShareLinks().email} target="_blank" rel="noopener noreferrer">
                                      <FaEnvelope size={30} color="#D44638" />
                                    </a>
                                  </div>
                                </div>
                              }
                              rows={tenants.map(tenant => [
                                [
                                  {
                                    type: "title",
                                    value: `${tenant.firstName} ${tenant.lastName}`,
                                  },
                                  {
                                    type: "title",
                                    value: <FaCheckCircle size={24} color="green" />
                                  },
                                ],
                              ])}
                            />
                          </Frame>

                        }

                        {!!contractServices && (
                          <Frame title="Your Services">
                            <OptimizedTable
                              rows={contractServices.map(contractService => [
                                [
                                  { type: "title", value: contractService.name },
                                  { type: "status", value: `Status: ${contractService.status}` },
                                ],
                                [
                                  { type: "text", value: contractService.supplier },
                                  { type: "text", value: contractService.utilityType },
                                ],
                              ])}
                            />
                          </Frame>
                        )}
                      </Frames>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )
      }
    </PageWrapper >
  )
}
