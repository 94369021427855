import React, { useCallback } from "react";
import PageWrapper from "../../components/PageWrapper";
import {
  getTenancyBillsAndPayments as apiGetTenancyBillsAndPayments,
  downloadFile
} from "../../services/tenantsApiV2";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import AppTitle from "../../components/appTitle/AppTitle";
import Frame from "../../components/frame/Frame";
import { b64toBlob } from "../../util/files";
import OptimizedTable from "../../components/optimizedTable/OptimizedTable"; // Import the table component
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material";
import MaintenanceAlert from "./components/MaintenanceAlert";


export default ({ location, tenancyId }) => {
  const context = React.useContext(GlobalStateContext);
  const [loading, setLoading] = React.useState(true);
  const [billsAndPayments, storeBillsAndPayments] = React.useState(null);

  const getBills = useCallback(async () => {
    try {
      setLoading(true);
      const data = await apiGetTenancyBillsAndPayments(tenancyId);
      if (data && data.value) {
        storeBillsAndPayments(data.value);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [tenancyId]);

  React.useEffect(() => {
    getBills();
  }, []);

  const handleDownload = async (bill) => {
    if (!bill.fileSource) {
      alert("File not available");
      return;
    }
  
    try {
      const response = await downloadFile(tenancyId, bill.fileKey);
      if (!response.ok) throw new Error("Failed to fetch file");
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      
      // Create a temporary link and trigger download
      const link = document.createElement("a");
      link.href = url;
      link.download = bill.fileName || "bill.pdf";
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
      alert("Failed to download file");
    }
  };

  const bills =
    billsAndPayments &&
    billsAndPayments.reduce((acc, bill) => {
      let { type } = bill;
      return { ...acc, [type]: [...(acc[type] || []), bill] };
    }, {});
    

    const renderBills = () => {
      if (!context.tenancy) {
        return <div>Loading tenancy details...</div>;
      }
    
      if (context.tenancy.isPhoenixAccount) {
        return (
          <TableContainer component={Paper} sx={{ maxWidth: "100%", margin: "auto", mt: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Start Date</strong></TableCell>
                  <TableCell><strong>End Date</strong></TableCell>
                  <TableCell><strong>Download</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billsAndPayments?.map((bill, index) => (
                  <TableRow key={index}>
                    <TableCell>{new Date(bill.startDate).toLocaleDateString("en-GB")}</TableCell>
                    <TableCell>{new Date(bill.endDate).toLocaleDateString("en-GB")}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownload(bill, `bill-${bill.startDate}.pdf`)}
                        disabled={!bill.fileSource}
                      >
                        📥 Download
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }

    if (billsAndPayments?.finalStatementUrl) {
      const contentType = "application/pdf";
      const blob = b64toBlob(billsAndPayments.finalStatementUrl, contentType);
      const blobUrl = URL.createObjectURL(blob);

      return (
        <div>
          Your account has been reconciled and you can download your final bill{" "}
          <a href={blobUrl} target="_blank" rel="noopener noreferrer" download="final-statement.pdf">
            here
          </a>
          .
        </div>
      );
    } else {
      return (
        <div>
          {bills &&
            Object.keys(bills)
              .sort((a, b) => a.localeCompare(b))
              .map((billType) => (
                <Frame title={billType} key={billType}>
                  {bills[billType].map((bill, index) => (
                    <div
                      key={index}
                      style={{
                        borderBottom: index === bills.length - 1 ? "0px solid #dedede" : "1px solid #dedede",
                        padding: 10,
                      }}
                    >
                      <div>Status: {bill.status}</div>
                      <div>
                        Period {new Date(bill.startDate).toLocaleDateString("en-GB")} to{" "}
                        {new Date(bill.endDate).toLocaleDateString("en-GB")}
                      </div>
                      <div>
                        {bill.breakdown &&
                          bill.breakdown.map((breakdownLineItem, breakdownIndex) => (
                            <div
                              key={breakdownIndex}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                maxWidth: "700px",
                                margin: "5px 0px",
                              }}
                            >
                              <div>{breakdownLineItem[0]}</div>
                              <div>{breakdownLineItem[1]}</div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </Frame>
              ))}
        </div>
      );
    }
  };

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={loading}
      showLoadingLogo={true}
      showLoadingText="Getting your live data"
    >
      <AppTitle title="Your Bills" />
      {process.env.GATSBY_USAGE_MAINTENANCE_MESSAGE ? 
      <MaintenanceAlert
      title="Maintenance Work"
      message="Your bills will be offline due to maintenance work. We apologise for any inconvenience caused."
    />
      : billsAndPayments?.length > 0 ? renderBills() : <div>We don't currently have any bills to show you. Please check back later!</div>}
    </PageWrapper>
  );
};
