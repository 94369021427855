import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const MaintenanceAlert = ({ title, message }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75vh", backgroundColor: "#f4f4f4", padding: "16px" }}>
      <Card sx={{ maxWidth: 500, width: "100%", textAlign: "center", boxShadow: 3, borderRadius: 3, padding: 3 }}>
        <CardContent>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            🚨 Major Changes Coming Soon! 🚨
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            We are rolling out updates to improve your experience. Stay tuned!
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            In the meantime, you can still add your Meter Readings or update your Payment Details and Password.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            If you need any further help, please contact our Customer Services team.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default MaintenanceAlert;